import React from "react";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { Modal } from "react-bootstrap";
import icon1 from "../../../img/icon1.png";
import icon1_h from "../../../img/icon1-h.png";
import icon2 from "../../../img/icon2.png";
import icon2_h from "../../../img/icon2-h.png";
import icon3 from "../../../img/icon3.png";
import icon3_h from "../../../img/icon3-h.png";
import * as myConsList from "../../Common/BaseUrl";

const baseUrl = myConsList.baseUrl;
const imgUrl = myConsList.imgUrl;

class Testimonial extends React.Component {


  constructor(props) {
    super(props);
    this.state = { tradingData: [],isModalModelOpen:false,modalData:{} };
    this.GetTradingData()
  }

  // componentDidMount = () => {
  //   this.setState({ tradingData: [],isModalModelOpen:false });
  // }

  GetTradingData = () => {
   // console.log("api call", this.state);
    axios.post(baseUrl + "/frontapi/get_web_trading_step", { lang: localStorage.getItem('lang') }).then((resp) => {
      resp = resp.data;
     // console.log(resp)
      if(resp.status && resp.data){
      this.setState({ tradingData: resp.data });
      }
      //clearInterval(this.state.intervelId);
    })
  };

   closeOpenModal = (data = {},status = false)=>{
   // console.log(data);
    this.setState({isModalModelOpen:status,modalData:data });
    
   }


  render() {

    const { t } = this.props;
    return (
      <div className="how-main-outer p60 text-center">
        <div className="container">
          <div className="section-heading mb-md-4">
            <h2>{t("Start Trading in Few Steps")}</h2>

          </div>
          <div className="how-outer">
            <div className="row">
              {(this.state) ? this.state.tradingData.map((val,index) =>
                <div className="col-md-3 col-12 mb-4" key={index}>
                  <div className="how-inner">
                    <div className="img-outer">
                      <img src={imgUrl + "/static/trading_steps/" + val.logo} alt="" className="img-fluid" />
                    </div>
                    <div className="content-outer">

                      <h3>{(val.title.length > 50)?val.title.substring(0, 50) + '...':val.title}</h3>                      
                      <p>{(val.description.length > 135)?val.description.substring(0, 135) + '...':val.description} <span style={{color:"#f1c40f",cursor: "pointer"}} onClick={()=>this.closeOpenModal(val,true)}>Read More</span></p>
                      
                    </div>
                   
                  </div>
                </div>
              ) : ""}

              {/* <div className="col-md-3 col-12 mb-4">
                <div className="how-inner ">
                  <div className="img-outer">
                    <img src="assets/img/cryptomining-hit.png" alt="" className="img-fluid" />
                  </div>
                  <div className="content-outer">

                    <h3>{t("Verify Your Account")}</h3>
                    <p>{t("Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.")}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 mb-4 ">
                <div className="how-inner ">
                  <div className="img-outer">
                    <img src="assets/img/crypto-wallet-hit.png" alt="" className="img-fluid" />
                  </div>
                  <div className="content-outer">

                    <h3>{t("Add Fund in Wallet")}</h3>
                    <p>{t("Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.")}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 mb-4">
                <div className="how-inner ">
                  <div className="img-outer">
                    <img src="assets/img/crypto-trading-hit.png" alt="" className="img-fluid" />
                  </div>
                  <div className="content-outer">

                    <h3>{t("Start Trading Instantiy")}</h3>
                    <p>{t("Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.")}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Modal
            show={this.state.isModalModelOpen}
            onHide={this.closeOpenModal}
            className="account-popup-outer"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> {this.state.modalData.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="wallet-balance form-group row">
              <div className="col-md-12 mb-3">
              <div className="img-outer" style={{textAlign:"center"}}>
              <img src={imgUrl + "/static/trading_steps/" + this.state.modalData.logo} alt="" className="img-fluid" />
              </div>
                </div>
                <div className="col-md-12 mb-3">
                  <span className="mb-0 form-control d-flex">
                  {this.state.modalData.description}
                    
                  </span>
                </div>
          </div>

            </Modal.Body>
          </Modal>
      </div>
    );
  }
}

export default withTranslation()(Testimonial);
