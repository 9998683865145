import React, { useEffect, useState } from "react";
import { Component } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../img/logo.png";
import * as myConstList from "./BaseUrl";
import ChangeLang from "./ChangeLang";
import axios from "axios";
// import jwt from "jsonwebtoken";
import config from "./jwt_config";
import { toast } from "react-toastify";
import LoginPin from "../Common/LoginPin";
import authService from "../../services/auth.service";
const baseUrl = myConstList.baseUrl;


export default function LoginHeader(props) {
  const userData = (localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")) : null;
  // console.log(userData);
  const { t } = useTranslation();
  const location = useLocation();
  const [total, setTotal] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setchecked] = useState(false);
  const [nickname, setNickname] = useState("Guest");
  const [Firstname, setFirstname] = useState("");
  const [LastName, setLastName] = useState("");
  const [accHolder, setAccHolder] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [kycStatus, setKycStatus] = useState("N");
  const [pair, setPair] = useState(localStorage.getItem("excoin1") ? localStorage.getItem("excoin1") + "/" + localStorage.getItem("excoin2") : null)
  const history = useHistory();
  useEffect(() => {
    var token1 = localStorage.getItem("token");
    if (token1 === null) {
      return history.push("/login-to-buy-sell-crypto");
    }
  });

  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
    }
    var theme = localStorage.getItem("theme");
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      handleChange(theme);
    }
  }, []);

  useEffect(() => {
    getData();
    getOpenPair();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      console.log("login header res ", resp.data);
      if (resp.status && resp.data.length) {
        if (resp.data[0].status == "1") {
          localStorage.setItem("userData", JSON.stringify(resp.data[0]));
        } else {
          authService.logout();
          history.push("/");
        }
          
        
      }
      if (
        resp.key === "logout" &&
        localStorage.getItem("token") !== "null" &&
        localStorage.getItem("token") !== null
      ) {
        // toast.error(resp.message);
        localStorage.clear();
        setTimeout(
          () => (window.location.href = "/login-to-buy-sell-crypto"),
          2000
        );
      }
      setKycStatus(resp.data[0].kycVerification_status)
      if (resp.data[0].nickname == null) {
        setNickname("Guest");
        setEmail(resp.data[0].email);
        setFirstname(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        // setAccHolder(resp.data[0].AccHolder);
        setMobile_no(resp.data[0].mobile_no);
      } else {
        setNickname(resp.data[0].nickname);
        setEmail(resp.data[0].email);
        setFirstname(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        // setAccHolder(resp.data[0].AccHolder);
        setMobile_no(resp.data[0].mobile_no);

      }
    });
  };
  const showHeaderPart = () => {
    var token = localStorage.getItem("token");
    if (token === null) {
      history.push("/login-to-buy-sell-crypto");
    } else {
      const maxCharacters = 19;
      const truncatedEmail =
        email && email.length > maxCharacters
          ? email.slice(0, maxCharacters) + "..."
          : email;
      return (
        <ul>
          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle d-flex align-items-center"
                data-toggle="dropdown"
                title={email ? email : mobile_no}
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
                <span className="mo_none"> {email ? truncatedEmail : mobile_no}</span>
              </span>
              <div className="dropdown-menu2">


                <Link className="a_flex" to="/profile">
                  <i className="fa fa-user  "></i> {t("Profile")}
                </Link>
                {/* <Link className="a_flex" to="/Transactions">
                  <i className="las la-wallet"></i> Transactions
                </Link> */}

                <Link className="a_flex" to="/Kyc">
                  <i className="fa fa-id-card-o"></i> {t("My KYC")} {(kycStatus == "Y") ? <i class="fa fa-check ml-3 text-success" aria-hidden="true"></i> : ""}
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="fa fa-headphones"></i> {t("Support")}
                </Link>
                <Link to="#" className="a_flex" onClick={logout}>
                  <i className="fa fa-sign-out"></i>{t("Logout")}
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };

  const logout = () => {
    authService.logout();
    toast.success(t("Logout Successfully"));
    history.push("/");
    // let interval = setInterval(() => {
    // var theme = localStorage.getItem("theme");
    // var lang = localStorage.getItem("lang");
    // let popUp = localStorage.getItem("homepopshow"); 
    // console.log(popUp);
    // localStorage.clear();
    // sessionStorage.clear();
    //sessionStorage.setItem("popshow",true);
    // localStorage.setItem("theme", theme);
    //localStorage.setItem("lang", lang);
    //localStorage.setItem("homepopshow", popUp);
    // history.push("/login-to-buy-sell-crypto");
    //clearInterval(interval);
    //}, 1000);
  };
  const handleChange = (checked) => {

    let tags = document.getElementsByTagName("body");

    if (checked === true) {
      tags[0].classList.add("dark-theme");
      setchecked(false);
    } else {
      tags[0].classList.remove("dark-theme");
      setchecked(true);
    }
    localStorage.setItem("theme", checked);
  };
  var token2 = localStorage.getItem("token");
  const getOpenPair = () => {
    if (!localStorage.getItem("excoin1")) {
      axios
        .get(baseUrl + "/frontapi/get-open-pair")
        .then((res) => {
          if (res.data.status) {
            setPair(
              `${res.data.data[0].first_name}/${res.data.data[0].second_name}`
            );
          }
        })
        .catch((err) => { });
    }
  };
  return (
    <div className="responsive_in ">

      <LoginPin />

      <nav className="navbar navbar-expand-lg nav-scroll2">
        <div className="container-fluid ">
          <Link className="navbar-brand" to="/index">
            <img
              src="assets/img/logo.png"
              alt="header-Logo"
              className="logo logo1"
            />
            <img
              src="/assets/img/logo-light.png"
              alt="header-Logo"
              className="logo logo2"
            />
          </Link>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav  line align-items-center">
              <li className="nav-item">
                {/* {!token2 ? (
                  <a className="nav-link " href="/exchange/TRX/USDT">
                    Exchange
                  </a>
                ) : (
                  <a className="nav-link " href="/exchange/TRX/USDT">
                    Exchange
                  </a>
                )} */}
                {!token2 ? (
                  <a className="nav-link " href={`/exchange/${pair}`}>
                    {t("Exchange")}
                  </a>
                ) : (
                  <a className="nav-link " href={`/exchange/${pair}`}>
                    {t("Exchange")}
                  </a>
                )}
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname == "/account" ? "active" : ""
                    }`}
                  to="/account"
                >
                  {t("Account")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname === "/transactions" ||
                    location.pathname === "/depositeHistory" ||
                    location.pathname === "/coinDeposite" ||
                    location.pathname === "/coinFundRequest"
                    ? "active"
                    : ""
                    }`}
                  to="/transactions"
                >
                  {t("Transactions")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${location.pathname == "/trade" ? "active" : ""
                    }`}
                  to="/trade"
                >
                  {t("Trade")}
                </Link>
              </li>

              <li>
                <Link className={`nav-link ${location.pathname == "/coinExchange" ? "active" : ""
                  }`} to="/coinExchange">
                  {t("Sale market")} &#8595;%
                </Link>
              </li>
              {/* <li>
              <a className={`nav-link ${location.pathname == "/listing/" + pair ? "active" : ""}`} href={`/listing/${pair}`}>
                    {t("Listing")}
                  </a>
              </li> */}

              <li>
                <ChangeLang />
              </li>
              <li className="nav-item">
                {checked == false ? (
                  <img
                    src="/assets/img/sun.png"
                    alt="sun"
                    width="25px"
                    className="sun-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                ) : (
                  <img
                    src="/assets/img/moon.png"
                    alt="moon"
                    width="25px"
                    className="moon-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                )}
              </li>
            </ul>
          </div>
          <div className="dashbord_menu">
            <ul className="">{showHeaderPart()}</ul>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="fa fa-bars fa-2x"></i>
            </span>
          </button>

        </div>
      </nav>
    </div>
  );
}
