import React,{useState,useEffect} from 'react'
import LoginHeader from "../Common/LoginHeader";
import axios from 'axios';
import { baseUrl } from '../Common/BaseUrl';
import { useParams } from 'react-router-dom';
import ExchangeKycHeader from '../Common/ExchangeHeader';
function BlogDetail() {
  const {id}=useParams()
  const [refresh, setRefresh] = useState(false)
    const [record, setRecord] = useState([])
    useEffect(() => {
      getData()
     }, [id])
      
     const getData=()=>{
     const data={
      id:id
     }
      axios.post(baseUrl+"/frontapi/get-blog-by-ids",data).then((res)=>{
        if(res.data.status){
          setRecord(res.data.data)
        }
        else{
          setRecord([])
        }
      }).catch((err)=>{
  
      })
     }
     const changeTheme=()=>{
      
    }
  return (
    <div className="">
    <ExchangeKycHeader graphColor={changeTheme} />
	  <div className="new_inpage ">
      <div className="container">
    <div className="row">
      {record.map((list)=>{
        return (
          <div className="col-md-8 m-auto">
          <div className=" blog_dital">
               <div className="blog_dital_img">
                 <img
                   src={baseUrl+"/static/blog/"+list.image}
                   alt=""
                  
                 />
               </div>
               <div className="content-outer mt-4">
                 <p className="mb-0">{list.date}</p>
                 <h2>{list.title}</h2>
                
               {/* <p>{list.description}

</p>  */}
          <div dangerouslySetInnerHTML={{__html: list.description}}></div>

               </div>
             </div>
            </div>
        )
      })}
      


  </div>
   </div>
    </div>

  </div>
  )
}

export default BlogDetail
