import { baseUrl } from "./BaseUrl";

export const checkPairapi=baseUrl+ "/frontapi/checkpair"
export const checkPairExchangeApi=baseUrl + "/frontapi/checkpairexchange"
export const checkFavCoinApi=baseUrl + "/frontapi/check-favcoin"
export const getOrderApi=baseUrl +"/frontapi/get_order"
export const deleteOrderApi=baseUrl+"/frontapi/delete_order"
export const cancelBackapi=baseUrl+"/frontapi/cancle-back"
export const getOpenPairApi=baseUrl+"/frontapi/get-open-pair"
export const userDataApi=baseUrl+"/frontapi/UserData"
export const cryptoDataByTab=baseUrl+"/frontapi/crypto-data-by-tab"
export const addFavCoinApi=baseUrl+"/frontapi/addFavCoin"
export const generatePayIdApi=baseUrl+"/frontapi/generate-pay-id"